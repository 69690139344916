import React from "react";
import Slider from "react-slick";
import "./TrustedBy.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class SimpleSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
    };
  }

  componentDidMount() {
    this.updateScreenSize();
    window.addEventListener("resize", this.updateScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenSize);
  }

  updateScreenSize = () => {
    const isMobile = window.innerWidth <= 768;
    this.setState({ isMobile });
  };

  render() {
    const companies = [
      {
        name: "Emaar",
        logo: "https://cdn.emaar.com/wp-content/themes/twentytwenty-child/assets/images/emaar-logo.svg",
        customClass: "Emaar",
      },
      {
        name: "Farnek",
        logo: "https://www.farnek.com/wp-content/uploads/2020/10/Farnek-logo-orange.png",
        customClass: "farnek-logo",
      },
      {
        name: "DubaiMall",
        logo: "https://m.eyeofriyadh.com/news_images/2019/03/2cd369942c1f2.png",
        customClass: "DubaiMall",
      },
      {
        name: "Enova",
        logo: "https://pbs.twimg.com/profile_images/1105051637427683328/udLetBdn_400x400.png",
        customClass: "Enova",
      },
      {
        name: "AlFuttaim",
        logo: "https://sab-us.com/web/image/res.partner/2668/image?unique=71f30dc",
        customClass: "alfuttaim-logo",
      },
      {
        name: "Emrill",
        logo: "https://www.emrill.com/wp-content/uploads/2019/07/Emrill.png",
        customClass: "Emrill",
      },
    ];

    const { isMobile } = this.state;

    let slidesToShow = 5;
    if (isMobile) {
      slidesToShow = 1;
    } else if (window.innerWidth <= 1156) {
      slidesToShow = 2;
    } else if (window.innerWidth <= 1600) {
      slidesToShow = 3;
    } else if (window.innerWidth <= 1800) {
      slidesToShow = 4;
    }

    const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 1700, //1700
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1700,
      cssEase: "linear",
      arrows: false,
      centerMode: true,
      swipeToSlide: true, // enable sliding on swipe
    };

    return (
      <section className="trusted-by">
        <h2>Trusted By</h2>
        <div className="companies mobile-carousel">
          <Slider {...sliderSettings}>
            {companies.map((company, index) => (
              <div key={index} className="logo-container2">
                <img
                  src={company.logo}
                  alt={company.name}
                  className={`company-logo ${company.customClass || ""}`}
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  }
}

export default SimpleSlider;
