// AdminPanel.js
import React from "react";

const AdminPanel = () => {
  return (
    <div>
      <h1>Welcome, admin!</h1>
      {/* Add your admin panel code here */}
    </div>
  );
};

export default AdminPanel;
