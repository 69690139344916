// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./Components/Header";
import TrustedBy from "./Components/TrustedBy";
import AboutUs from "./Components/AboutUs";
import Projects from "./Components/Projects";
import ContactUs from "./Components/ContactUs";
import Services from "./Components/Services";
import Footer from "./Components/Footer";
import AdminLogin from "./Components/AdminLogin"; // add this line
import AdminPanel from "./Components/AdminPanel"; // add this line
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <AboutUs />
                <Services />
                <Projects />
                <TrustedBy />
              </>
            }
          />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/admin-login" element={<AdminLogin />} />{" "}
          {/* add this line */}
          <Route path="/admin" element={<AdminPanel />} /> {/* add this line */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
