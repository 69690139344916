import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import logo from "../Imgs/logo.png";
import { Link } from "react-router-dom";
import pic1 from "../Imgs/Work/pic1.png";
import "./Header.css";
import { ImWhatsapp } from "react-icons/im";
import { RxDoubleArrowDown } from "react-icons/rx";

const Header = () => {
  const emptyForm = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };
  const [showArrow, setShowArrow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ ...emptyForm });

  useEffect(() => {
    const checkScroll = () => {
      // When user has scrolled more than 10 pixels, hide the arrow
      if (window.pageYOffset > 10) {
        setShowArrow(false);
      } else {
        setShowArrow(true);
      }
    };

    // Add the event listener
    window.addEventListener("scroll", checkScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "service_81v2sbr",
        "template_kmmmyea",
        e.target,
        "gYX9isnWXQOYiELzC"
      )
      .then(
        (result) => {
          console.log(result.text);
          setForm({ ...emptyForm });
          alert(
            "Your submission has been successfully received! Expect a response from us within the next 24 hours."
          );
          setLoading(false);
        },
        (error) => {
          console.log("Form submission error", error.text);
          setLoading(false);
        }
      );
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleWhatsappClick = () => {
    window.open("https://wa.me/00971504419960", "_blank");
  };

  return (
    <div className="background">
      <div
        style={{
          backgroundImage: `url(${pic1})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        alt="background"
        className="background-image"
      />
      <div className="top-box">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
          <p>Contact Us</p>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            value={form.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={form.email}
            onChange={handleChange}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone"
            value={form.phone}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            value={form.subject}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            placeholder="Message"
            value={form.message}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit" disabled={loading}>
            {loading ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
      <div className="whatsapp-logo" onClick={handleWhatsappClick}>
        <ImWhatsapp size={70} color="#25D366" />
      </div>
      <div
        className="arrow-animation"
        style={{ display: showArrow ? "block" : "none" }}
      >
        <RxDoubleArrowDown size={30} color="white" />
      </div>
    </div>
  );
};

export default Header;
