import React, { useEffect, useState } from "react";
import "./Services.css";
import "react-intersection-observer";
import { useMemo } from "react";
import pic2 from "../Imgs/Work/pic2.png";
import pic4 from "../Imgs/Work/pic4.png";
import pic8 from "../Imgs/Work/pic8.png";
import pic15 from "../Imgs/Work/pic15.png";
import pic16 from "../Imgs/Work/pic16.png";
import pic17 from "../Imgs/Work/pic17.png";
import pic18 from "../Imgs/Work/pic18.png";
import pic19 from "../Imgs/Work/pic19.png";
import pic20 from "../Imgs/Work/pic20.png";
import pic21 from "../Imgs/Work/pic21.png";
import pic22 from "../Imgs/Work/pic22.png";
import pic23 from "../Imgs/Work/pic23.png";
import pic24 from "../Imgs/Work/pic24.png";

const Services = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = (f) => setIsLargeScreen(window.innerWidth > 768);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const services = useMemo(
    () => [
      {
        title: "Offices Fit-Out",
        description:
          "Our experienced team ensures every aspect of the office fit-out is meticulously planned and executed to maximize efficiency and enhance employee well-being.",
        image: pic18,
        alt: "Offices Fit-Out",
      },
      {
        title: "Entertainment Fit-Out",
        description:
          "We specialize in creating captivating and immersive entertainment spaces through our expert fit-out services, combining innovative design and cutting-edge technology.",
        image: pic20,
        alt: "Entertainment Fit-Out",
      },
      {
        title: "Retail Fit-Out",
        description:
          "Our retail fit-out services revitalize and enhance retail spaces, combining aesthetic appeal, strategic layout, and customer-friendly elements to create an inviting and engaging shopping experience.",
        image: pic2,
        alt: "Retail Fit-Out",
      },
      {
        title: "Mall Fit-Out",
        description:
          "Our expert mall fit-out services ensure an exceptional shopping experience by optimizing layout, design, and functionality with a perfect blend of aesthetics and convenience.",
        image: pic22,
        alt: "Mall Fit-Out",
      },
      {
        title: "Marbel Supply and Installation",
        description:
          "Elevate your marble trading endeavors with our fit-out services, emphasizing elegance and exceptional quality in every supply and installation project.",
        image: pic19,
        alt: "Marble Trading Fit-Out",
      },
      {
        title: "General Maintenance",
        description:
          "Our maintenance services of buildings prioritize efficiency and quality, ensuring timely and expert maintenance solutions to keep your spaces in optimal condition.",
        image: pic8,
        alt: "Building Fit-Out",
      },
    ],
    []
  );
  const [bgColor, setBgColor] = useState("black");
  const colors = ["black", "red", "blue"];

  const renderContent = (service, index) => (
    <>
      {index % 2 === 0 ? (
        <>
          <img
            className={`service-img ${isLargeScreen ? "right-img" : ""}`}
            src={service.image}
            alt={service.alt}
          />
          <div className="unique-content">
            <h3 className="headOfText">{service.title}</h3>
            <p className="sentence">{service.description}</p>
          </div>
        </>
      ) : (
        <>
          <div className="unique-content">
            <h3 className="headOfText">{service.title}</h3>
            <p className="sentence">{service.description}</p>
          </div>
          <img
            className={`service-img ${isLargeScreen ? "right-img" : ""}`}
            src={service.image}
            alt={service.alt}
          />
        </>
      )}
    </>
  );

  return (
    <section className="unique-services">
      <h1 className="head">Services and Core Competencies</h1>
      <div className="unique-box">
        {isLargeScreen
          ? services.reduce((acc, curr, idx, arr) => {
              if (idx % 2 === 0) {
                const chunk = arr.slice(idx, idx + 2);
                acc.push(...chunk.map((service) => renderContent(service)));
              }
              return acc;
            }, [])
          : services.map((service) => renderContent(service))}
      </div>
    </section>
  );
};

export default Services;
