import React from "react";
import "./AboutUs.css";
import BurjKhalifa from "../Imgs/BurjKhalifa.jpeg"; // Updated import

const AboutUs = () => {
  return (
    <section className="about-us">
      <div className="about-us-text">
        <h2 className="about">About Us</h2>
        <p className="theText2">
          Fit-Out Co. is a UAE-based leader in fit-out and maintenance with over
          15 years of experience. We've completed notable projects like a 60,000
          sqm tiling installation in Sharjah Free Zone. Our reputation is built
          on quality workmanship, ethical practices, and adaptability. We strive
          to exceed client expectations, align with project objectives, and
          build lasting relationships.
        </p>
      </div>
      <div className="separator"></div>

      <div className="BurjKhalifa">
        <img src={BurjKhalifa} alt="BurjKhalifa" className="BurjKhalifa2" />{" "}
        {/* Updated src */}
      </div>
    </section>
  );
};

export default AboutUs;
