// Projects.js
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Projects.css";
import pic1 from "../Imgs/Work/pic1.png";
import pic2 from "../Imgs/Work/pic2.png";
import pic3 from "../Imgs/Work/pic3.png";
import pic4 from "../Imgs/Work/pic4.png";
import pic5 from "../Imgs/Work/pic5.png";
import pic6 from "../Imgs/Work/pic6.png";
import pic7 from "../Imgs/Work/pic7.png";
import pic8 from "../Imgs/Work/pic8.png";
import pic9 from "../Imgs/Work/pic9.png";
import pic10 from "../Imgs/Work/pic10.png";
import pic11 from "../Imgs/Work/pic11.png";
import pic12 from "../Imgs/Work/pic12.png";
import pic13 from "../Imgs/Work/pic13.png";
import pic14 from "../Imgs/Work/pic14.png";
import pic15 from "../Imgs/Work/pic15.png";
import pic16 from "../Imgs/Work/pic16.png";
import pic17 from "../Imgs/Work/pic17.png";
import pic21 from "../Imgs/Work/pic21.png";
import pic23 from "../Imgs/Work/pic23.png";
import pic24 from "../Imgs/Work/pic24.png";
import pic25 from "../Imgs/Work/pic25.png";
import pic26 from "../Imgs/Work/pic26.png";

const Projects = () => {
  const projectImages = [
    pic1,
    pic4,
    pic5,
    pic6,
    pic7,
    pic8,
    pic9,
    pic10,
    pic11,
    pic12,
    pic13,
    pic15,
    pic16,
    pic17,
    pic21,
    pic23,
    pic24,
    pic25,
    pic26,
  ];

  let slidesToShow = 1;

  if (window.innerWidth >= 400) {
    slidesToShow = 2;
  }
  if (window.innerWidth >= 768) {
    slidesToShow = 3;
  }
  if (window.innerWidth >= 1024) {
    slidesToShow = 4;
  }
  if (window.innerWidth >= 1400) {
    slidesToShow = 5;
  }
  if (window.innerWidth >= 1600) {
    slidesToShow = 6;
  }
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="projects">
      <h2>Our Projects</h2>
      <Slider {...settings}>
        {projectImages.map((image, index) => (
          <div key={index} className="project-slide">
            <img
              src={image}
              alt={`Project ${index + 1}`}
              className="project-image"
            />
          </div>
        ))}
      </Slider>
      <div className="separator2"></div>
    </section>
  );
};

export default Projects;
